<template>
  <!-- 总装登记 -->
  <div>
    <div class="zzCheckIn_main">
      <van-nav-bar title="总装车间"
                   left-arrow
                   @click-left="onClickLeft"
                   :fixed="true" />
      <div class="content"
           :style="viewType != '3'?'height: calc(100% - 89px);':'height: calc(100% - 48px);'">
        <div class="form_and_table">
          <van-form id="main_form">
            <van-field class="not_canedi"
                       :label="'工号'"
                       v-model="form.empcode"
                       readonly />
            <van-field class="not_canedi"
                       :label="'姓名'"
                       v-model="form.empname"
                       readonly />
            <van-field class="not_canedi"
                       :label="'厂别'"
                       v-model="form.deptname1"
                       readonly />
            <van-field class="not_canedi"
                       :label="'班/组/线'"
                       v-model="form.deptname2"
                       readonly />
            <van-field v-if="canEdit"
                       readonly
                       clickable
                       right-icon="edit"
                       name="picker"
                       :required="true"
                       :value="form.min_deptname"
                       :label="'最小部门'"
                       :placeholder="$t('module.PleaseChoose')"
                       @click="getDatePicker('dept')" />
            <van-field v-else
                       class="not_canedi"
                       :label="'最小部门'"
                       v-model="form.min_deptname"
                       readonly />
            <van-field v-if="canEdit"
                       clickable
                       readonly
                       :clearable="true"
                       clear-trigger="always"
                       :required="true"
                       right-icon="edit"
                       name="datePicker"
                       :label="'生产日期'"
                       :value="formatDate(form.yymmdd, 'yyyy-MM-dd')"
                       :placeholder="$t('module.SelectDate')"
                       @clear="clearDate( )"
                       @click="getDatePicker('yymmdd')" />
            <van-field v-else
                       class="not_canedi"
                       :label="'生产日期'"
                       v-model="form.yymmdd"
                       readonly />
            <van-field class="not_canedi"
                       :label="'编号'"
                       v-model="form.listno"
                       readonly />
            <van-field class="not_canedi"
                       :label="'制表人'"
                       v-model="form.createdPeo"
                       readonly />
            <van-field class="not_canedi"
                       :label="'制表日期'"
                       v-model="form.createDate"
                       readonly />
          </van-form>
          <div class="isSon_body"
               v-if="autoid">
            <!-- 子表页签Tab -->
            <van-tabs v-model="activeSon"
                      @change="changTab">
              <van-tab title="生产明细"
                       name="1"> </van-tab>
              <van-tab title="工序明细"
                       name="2"> </van-tab>
              <van-tab title="人员明细"
                       name="3"> </van-tab>
            </van-tabs>
            <div class="sonTable_btn"
                 v-if="showSonBtn && canEdit">
              <van-button type="primary"
                          size="small"
                          @click="goToAdd">新增</van-button>
              <van-button type="default"
                          size="small"
                          @click="goToEdit">修改</van-button>
              <van-button type="warning"
                          size="small"
                          @click="goToDelete">删除</van-button>
            </div>
            <div class="sontable_box">
              <table ref="table"
                     class="table">
                <!-- 表头 -->
                <tr class="top">
                  <th v-for="(item_th, index_th) in auditThList"
                      :key="index_th">
                    <div class="title"
                         :style="item_th.gridwith ? { width: item_th.gridwith * 1.5 + 'px' } : 'min-width:90px'">
                      {{ item_th.chnname }}
                    </div>
                  </th>
                </tr>
                <!--展示列表数据 -->
                <tr class="cont"
                    v-for="(item_tr, index_tr) in auditTableData"
                    :key="index_tr"
                    @click.stop="changeAuditDataByTd(item_tr)">
                  <td v-for="(item_td, index_td) in auditThList"
                      :key="index_td">
                    <div class="comm_box">
                      <div class="boolean_box"
                           v-if="item_td.datatype == 'checkbox'">
                        <van-checkbox v-model="item_tr[item_td.fieldname]"></van-checkbox>
                      </div>
                      <div class="boolean_box"
                           v-else-if="item_td.datatype == 'checkbox_disable'">
                        <van-checkbox v-model="item_tr[item_td.fieldname]"
                                      shape="square"
                                      disabled></van-checkbox>
                      </div>
                      <div class="input_box"
                           v-else-if="item_td.datatype == 'num'">
                        <van-field v-model="item_tr[item_td.fieldname]"
                                   :placeholder="item_td.chnname" />
                      </div>
                      <div v-else>{{ item_tr[item_td.fieldname] }}</div>
                    </div>
                  </td>
                </tr>
                <tr class="nodata"
                    v-if="auditTableData.length == 0">
                  <td align="center"
                      :colspan="auditThList.length">暂无数据</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- 底部按钮 -->
      <div class="footerBox"
           v-if="viewType != '3'">
        <div class="flexBox"
             v-if="canEdit">
          <van-button v-if="viewType != '2'"
                      @click="staging"
                      type="primary"
                      style="width: 45%;height: 100%;">暂存</van-button>
          <van-button @click="submit"
                      type="info"
                      style="width: 45%;height: 100%;">提交</van-button>
        </div>
        <div class="flexBox"
             v-else>
          <van-button @click="agree"
                      type="primary"
                      style="width: 45%;height: 100%;">同意</van-button>
          <van-button @click="disagree"
                      type="info"
                      style="width: 45%;height: 100%;">不同意</van-button>
        </div>
      </div>
    </div>
    <!-- 日期日历弹窗 -->
    <van-calendar v-model="showCalendar"
                  :type="'single'"
                  :allow-same-day="false"
                  :min-date="minDate"
                  :max-date="maxDate"
                  @confirm="onConfirmCal" />
    <!-- 选择部门树弹窗 -->
    <PopTree ref="popTreeNode"
             :whichTree="TreeName"
             module="817"
             :multiseltree="false"
             @treeSelectValEvent="treeSelectValEvent"></PopTree>
    <!-- 生产明细编辑弹窗 -->
    <van-popup v-model="showEditSC"
               position="right"
               :style="{ height: '100%' , width: '100%'}"
               class="editSC_main_pop">
      <van-nav-bar title="生产明细"
                   left-arrow
                   @click-left="showEditSC = false"
                   :fixed="true" />
      <div class="content">
        <van-form>
          <van-field v-if="editType == 'add'"
                     required
                     readonly
                     clickable
                     label="总计划单号"
                     right-icon="edit"
                     v-model="scForm.orderid"
                     :placeholder="$t('module.PleaseChoose')"
                     @click="showJhPicker = true" />
          <van-field v-else
                     class="not_canedi"
                     :label="'总计划单号'"
                     v-model="scForm.orderid    "
                     readonly />
          <van-field class="not_canedi"
                     :label="'产品型号'"
                     v-model="scForm.cpxh    "
                     readonly />
          <van-field class="not_canedi"
                     :label="'组合名称'"
                     v-model="scForm.zhmc"
                     readonly />
          <van-field class="not_canedi"
                     :label="'需求分类'"
                     v-model="scForm.xqfl"
                     readonly />
          <van-field :required="true"
                     :label="'订单总数/台'"
                     type="digit"
                     right-icon="edit"
                     v-model="scForm.ddts"
                     :placeholder="$t('module.PleaseEnter')" />
          <van-field :required="true"
                     :label="'实际生产数/台'"
                     type="digit"
                     right-icon="edit"
                     v-model="scForm.sjscjts"
                     :placeholder="$t('module.PleaseEnter')" />
          <van-field :required="true"
                     :label="'实际生产人数/人'"
                     type="digit"
                     right-icon="edit"
                     v-model="scForm.sjscrs"
                     :placeholder="$t('module.PleaseEnter')" />
          <van-field v-if="editType == 'add'"
                     :required="true"
                     :label="'实际上班工时/小时'"
                     type="number"
                     right-icon="edit"
                     v-model="scForm.sjsbgs"
                     :placeholder="$t('module.PleaseEnter')" />
          <van-field v-else
                     class="not_canedi"
                     :label="'实际上班工时/小时'"
                     v-model="scForm.sjsbgs    "
                     readonly />
          <van-field class="not_canedi"
                     :label="'是否为新产品'"
                     v-model="scForm.isxp    "
                     readonly />
          <van-field class="not_canedi"
                     :label="'组合单价/（元/台）'"
                     v-model="scForm.zhdj    "
                     readonly />
          <van-field class="not_canedi"
                     :label="'计件总工资/元'"
                     v-model="scForm.jjzgz    "
                     readonly />
          <van-field class="not_canedi"
                     :label="'新产品时薪/（元/小时）'"
                     v-model="scForm.xcpsx    "
                     readonly />
          <van-field class="not_canedi"
                     :label="'总工资/元'"
                     v-model="scForm.zgz    "
                     readonly />
          <van-field class="not_canedi"
                     :label="'计时总工资/元'"
                     v-model="scForm.jszgz    "
                     readonly />
          <van-field :label="'未完成原因'"
                     v-model="scForm.remark"
                     :required="false"
                     right-icon="edit"
                     rows="3"
                     autosize
                     type="textarea"
                     :placeholder="$t('module.PleaseEnter')" />
          <van-field class="not_canedi"
                     :label="'责任生产单位'"
                     v-model="scForm.zrscdw"
                     readonly />
          <van-field readonly
                     clickable
                     right-icon="edit"
                     name="picker"
                     :required="true"
                     :value="scForm.zrr_empname"
                     :label="'责任人'"
                     :placeholder="$t('module.PleaseChoose')"
                     @click="getDatePicker('emp')" />
          <van-field class="not_canedi"
                     :label="'包装验货数'"
                     v-model="scForm.bzyhs"
                     readonly />
          <van-field class="not_canedi"
                     :label="'订单累计完成数/台'"
                     v-model="scForm.ddljwcs"
                     readonly />
        </van-form>
        <div class="footerBox">
          <div class="flexBox">
            <van-button @click="confirmSC"
                        type="info"
                        style="width: 80%;height: 100%;">确定</van-button>

          </div>
        </div>
      </div>
    </van-popup>
    <!-- 计划单号下拉弹窗 -->
    <van-popup v-model="showJhPicker"
               round
               position="bottom">
      <van-picker show-toolbar
                  :columns="dhcolumns"
                  value-key="orderid"
                  :confirm-button-text="$t('module.confirm')"
                  :cancel-button-text="$t('module.cancel')"
                  @cancel="showJhPicker = false"
                  @confirm="onConfirmDh">
        <template #title>
          <van-search shape="round"
                      v-model="searchDH"
                      placeholder="搜索关键词"
                      @input="searchDHList" />
        </template>
      </van-picker>
    </van-popup>

    <!-- 人员明细编辑弹窗 -->
    <van-popup v-model="showEditRY"
               position="right"
               :style="{ height: '100%' , width: '100%'}"
               class="editSC_main_pop">
      <van-nav-bar title="人员明细"
                   left-arrow
                   @click-left="showEditRY = false"
                   :fixed="true" />
      <div class="content">
        <van-form>
          <van-field readonly
                     clickable
                     right-icon="edit"
                     name="picker"
                     :required="true"
                     :value="ryForm.empcode"
                     :label="'工号'"
                     :placeholder="$t('module.PleaseChoose')"
                     @click="getDatePicker('ryemp')" />
          <van-field class="not_canedi"
                     :label="'姓名'"
                     v-model="ryForm.empname    "
                     readonly />
          <van-field :required="true"
                     :label="'上班工时/小时'"
                     type="number"
                     right-icon="edit"
                     v-model="ryForm.sbgs"
                     :placeholder="$t('module.PleaseEnter')" />
          <van-field :required="true"
                     :label="'请假工时/小时'"
                     type="number"
                     right-icon="edit"
                     v-model="ryForm.qjgs"
                     :placeholder="$t('module.PleaseEnter')" />
          <van-field :required="true"
                     :label="'实际上班工时/小时'"
                     type="number"
                     right-icon="edit"
                     v-model="ryForm.sjsbgs"
                     :placeholder="$t('module.PleaseEnter')" />
          <van-field :required="true"
                     :label="'当天理论工资/元'"
                     type="number"
                     right-icon="edit"
                     v-model="ryForm.dtllgz"
                     :placeholder="$t('module.PleaseEnter')" />
          <van-field :required="true"
                     :label="'当天理论时薪/（元/小时）'"
                     type="number"
                     right-icon="edit"
                     v-model="ryForm.dtllsx"
                     :placeholder="$t('module.PleaseEnter')" />
          <van-field right-icon="edit"
                     name="switch"
                     :required="true"
                     :label="'是否借调'">
            <template #input>
              <van-switch v-model="ryForm.isjd"
                          size="20" />
            </template>
          </van-field>
          <van-field :label="'备注'"
                     v-model="ryForm.remark"
                     :required="false"
                     right-icon="edit"
                     rows="3"
                     autosize
                     type="textarea"
                     :placeholder="$t('module.PleaseEnter')" />

        </van-form>
        <div class="footerBox">
          <div class="flexBox">
            <van-button @click="confirmRY"
                        type="info"
                        style="width: 80%;height: 100%;">确定</van-button>

          </div>
        </div>
      </div>
    </van-popup>
  </div>

</template>

<script>
import { Toast, Dialog } from 'vant'
import {
  getCurUserInfo,
  getScDdList,
  getScDdExitValue,
  saveZzInputData,
  getZzInputData,
  addZzScmxRecord,
  saveZzScmxRecord,
  deleteZzScmxRecord,
  addZzEmpRecord,
  saveZzEmpRecord,
  deleteZzEmpRecord,
  postZzInputData,
  agreeTmList,
  notAgreeTmlist
} from '@api/wxjj.js'
import PopTree from "@components/PopTree/index.vue";
export default {
  components: {
    PopTree
  },
  data () {
    const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
    return {
      userInfo,
      viewType: 0, // 1待审批 2待提交 3已审批 4不同意
      canEdit: true,
      moduleno: 817,
      autoid: 0,
      TreeName: '',
      whichTree: "",
      form: {
        autoid: null,
        empcode: '',
        empname: '',
        deptname1: '',
        deptname2: '',
        min_deptname: '',
        yymmdd: '',
        listno: '',
        createdPeo: '',
        createDate: this.formatDate(new Date(), 'yyyy-MM-dd')
      },
      showCalendar: false,
      minDate: new Date(new Date().getFullYear() - 20, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 20, 0, 31),
      activeSon: '1',
      auditThList: [], // 表头
      auditTableData: [], // 表格数据
      initAuditTableData: [], // // 表格数据初始数据
      showSonBtn: true,
      scmx_data: [], // 生产明细
      gxmx_data: [], // 工序明细
      rymx_data: [], // 人员明细 
      /*** 生产明细子表弹窗参数-----------start */
      showEditSC: false, // 生产明细弹窗
      editType: 'add',
      scForm: {
        mautoid: null,
        orderid: '', // --总计划单号
        cpxh: '',
        zhmc: '',
        xqfl: '',
        ddts: '', // --订单台数
        sjscjts: '', // --实际生产台数
        sjscrs: '', // --实际生产人数
        sjsbgs: '', //  --实际上班工时
        isxp: '',
        zhdj: '',
        jjzgz: '',
        xcpsx: '',
        zgz: '',
        jszgz: '',
        zrscdw: '',
        zrr_empid: '', // --责任人工号id
        zrr_empname: "",
        remark: '', // --未完成原因
        bzyhs: '',
        ddljwcs: ''
      },
      showJhPicker: false,
      dhcolumns: [],
      searchDH: '',
      /*** 生产明细子表弹窗参数-----------end */
      /*** 人员明细子表弹窗参数-----------start */
      showEditRY: false, // 人员明细弹窗 
      ryForm: {
        mautoid: null,
        empid: '', // --工号id
        empcode: '',
        empname: '',
        sbgs: '', // --上班工时
        qjgs: '', // --请假工时
        sjsbgs: '', // --实际上班工时
        dtllgz: '', // --当天理论工资
        dtllsx: '', // --当天理论时薪
        isjd: false,  // --是否借调
        remark: '' // --未完成原因 
      },
      /*** 人员明细子表弹窗参数-----------end */

    }
  },
  watch: {
    showJhPicker (n) {
      if (n)
      {
        this.getScDdListData();
      } else
      {
        this.searchDH = ''
      }
    }
  },
  mounted () {
    // // 1待审批 2待提交 3已审批 4不同意
    if (this.$route.query.type)
    {
      this.viewType = this.$route.query.type
      if (this.viewType == '1' || this.viewType == '3')
      {
        this.canEdit = false;
      }
    }
    if (this.$route.query.autoid)
    {
      this.autoid = this.$route.query.autoid
    }
    if (this.autoid == 0)
    {
      this.getCurUserInfoData()
    }
    this.auditThList = [
      {
        fieldname: "checked",
        chnname: "",
        gridwith: 10,
        datatype: 'checkbox'
      },
      {
        fieldname: "orderid",
        chnname: "计划单号",
        gridwith: 60,
        datatype: 'str'
      },
      {
        fieldname: "cpxh",
        chnname: "产品型号",
        gridwith: 40,
        datatype: 'str'
      },
      {
        fieldname: "zhmc",
        chnname: "组合名称",
        gridwith: 60,
        datatype: 'str'
      },
      {
        fieldname: "xqfl",
        chnname: "需求分类",
        gridwith: 60,
        datatype: 'str'
      },
      {
        fieldname: "ddts",
        chnname: "订单总数/台",
        gridwith: 60,
        datatype: 'str'
      },
      {
        fieldname: "pcsl",
        chnname: "批次数量/台",
        gridwith: 60,
        datatype: 'str'
      },
      {
        fieldname: "drjhsl",
        chnname: "当日计划数量/台",
        gridwith: 60,
        datatype: 'str'
      },
      {
        fieldname: "sjscrs",
        chnname: "实际生产人数/人",
        gridwith: 60,
        datatype: 'str'
      },
      {
        fieldname: "sjsbgs",
        chnname: "实际上班工时/小时",
        gridwith: 60,
        datatype: 'str'
      },
      {
        fieldname: "isxp",
        chnname: "是否为新产品",
        gridwith: 60,
        datatype: 'str'
      },
      {
        fieldname: "zhdj",
        chnname: "组合单价/（元/台）",
        gridwith: 60,
        datatype: 'str'
      },
      {
        fieldname: "jjzgz",
        chnname: "计件总工资/元",
        gridwith: 60,
        datatype: 'str'
      },
      {
        fieldname: "xcpsx",
        chnname: "新产品时薪/（元/小时）",
        gridwith: 60,
        datatype: 'str'
      },
      {
        fieldname: "jszgz",
        chnname: "计时总工资/元",
        gridwith: 60,
        datatype: 'str'
      },
      {
        fieldname: "zgz",
        chnname: "总工资/元",
        gridwith: 60,
        datatype: 'str'
      },
      {
        fieldname: "remark",
        chnname: "未完成原因",
        gridwith: 100,
        datatype: 'str'
      },
      {
        fieldname: "zrscdw",
        chnname: "责任生产单位",
        gridwith: 60,
        datatype: 'str'
      },
      {
        fieldname: "zrr_empname",
        chnname: "责任人",
        gridwith: 60,
        datatype: 'str'
      },
      {
        fieldname: "bzyhs",
        chnname: "包装验货数",
        gridwith: 60,
        datatype: 'str'
      },
      {
        fieldname: "ddljwcs",
        chnname: "订单累计完成数/台",
        gridwith: 60,
        datatype: 'str'
      },
      {
        fieldname: "ddwwcs",
        chnname: "订单未完成数/台",
        gridwith: 60,
        datatype: 'str'
      }
    ]
    this.getZzInputDataInfo()
  },
  methods: {
    // 同意单据
    agree () {
      agreeTmList({
        moduleno: this.moduleno,
        autoid: this.autoid,
        username: this.userInfo.username
      }).then(res => {
        if (res.data[0].info)
        {
          Toast.fail(res.data[0].info)
        } else
        {
          Toast.success('审批成功！');
          this.onClickLeft()
        }
      })
    },
    // 不同意单据
    disagree () {
      notAgreeTmlist({
        moduleno: this.moduleno,
        autoid: this.autoid,
        username: this.userInfo.username
      }).then(res => {
        if (res.data[0].info)
        {
          Toast.fail(res.data[0].info)
        } else
        {
          Toast.success('审批成功！');
          this.onClickLeft()
        }
      })
    },

    // 人员明细弹窗确定按钮
    confirmRY () {
      if (!this.ryForm.empcode)
      {
        Toast.fail('工号不能为空！')
        return
      }
      if (!this.ryForm.sbgs)
      {
        Toast.fail('上班工时不能为空！')
        return
      }
      if (!this.ryForm.qjgs)
      {
        Toast.fail('请假工时不能为空！')
        return
      }
      if (!this.ryForm.sjsbgs)
      {
        Toast.fail('实际上班工时不能为空！')
        return
      }
      if (!this.ryForm.dtllgz)
      {
        Toast.fail('当天理论工资不能为空！')
        return
      }
      if (!this.ryForm.dtllsx)
      {
        Toast.fail('当天理论时薪不能为空！')
        return
      }
      this.ryForm.username = this.userInfo.username
      if (this.editType == 'add')
      {
        // 新增
        this.ryForm.mautoid = this.autoid
        addZzEmpRecord(this.ryForm).then(res => {
          if (res.data[0].info)
          {
            Toast.fail(res.data[0].info)
          } else
          {
            Toast.success('新增成功！')
            this.showEditRY = false
            this.getZzInputDataInfo()
          }
        })
      } else
      {
        // 修改 
        saveZzEmpRecord(this.ryForm).then(res => {
          if (res.data[0].info)
          {
            Toast.fail(res.data[0].info)
          } else
          {
            Toast.success('修改成功！')
            this.showEditRY = false
            this.getZzInputDataInfo()
          }
        })
      }
    },
    // 生产明细弹窗确定按钮
    confirmSC () {
      if (!this.scForm.orderid)
      {
        Toast.fail('总计划单号不能为空！')
        return
      }
      if (!this.scForm.ddts)
      {
        Toast.fail('订单总数不能为空！')
        return
      }
      if (!this.scForm.sjscjts)
      {
        Toast.fail('实际生产台数不能为空！')
        return
      }
      if (!this.scForm.sjscrs)
      {
        Toast.fail('实际生产人数不能为空！')
        return
      }
      if (!this.scForm.sjsbgs)
      {
        Toast.fail('实际上班工时不能为空！')
        return
      }
      if (!this.scForm.zrr_empname)
      {
        Toast.fail('责任人不能为空！')
        return
      }

      if (this.editType == 'add')
      {
        // 新增
        this.scForm.mautoid = this.autoid
        addZzScmxRecord(this.scForm).then(res => {
          if (res.data[0].info)
          {
            Toast.fail(res.data[0].info)
          } else
          {
            Toast.success('新增成功！')
            this.showEditSC = false
            this.getZzInputDataInfo()
          }
        })
      } else
      {
        // 修改 
        saveZzScmxRecord(this.scForm).then(res => {
          if (res.data[0].info)
          {
            Toast.fail(res.data[0].info)
          } else
          {
            Toast.success('修改成功！')
            this.showEditSC = false
            this.getZzInputDataInfo()
          }
        })
      }
    },
    // 计划单号下拉搜索
    searchDHList () {
      if (this.searchDH)
      {
        this.dhcolumns = this.dhcolumns.filter(it => {
          return it.orderid.indexOf(this.searchDH) >= 0
          // || it.processcode.indexOf(this.searchDH) >= 0
        })
      } else
      {
        this.getScDdListData();
      }
    },
    // 计划单号弹窗回调
    onConfirmDh (value) {
      this.scForm.orderid = value.orderid
      this.showJhPicker = false
      this.getScDdExitValueData()
    },
    // 获取生产订单号列表
    getScDdListData () {
      getScDdList({
        username: this.userInfo.username
      }).then(res => {
        this.dhcolumns = res.data
      })
    },
    // 根据生产订单号获取相关信息
    getScDdExitValueData () {
      getScDdExitValue({
        orderid: this.scForm.orderid,
        username: this.userInfo.username
      }).then(res => {
        const obj = res.data[0]
        this.scForm = { ...this.scForm, ...obj }
      })
    },
    // 子表新增按钮
    goToAdd () {
      if (this.activeSon == '1')
      {
        // 去生产明细
        this.editType = 'add'
        this.scForm = {
          mautoid: null,
          orderid: '', // --总计划单号
          cpxh: '',
          zhmc: '',
          xqfl: '',
          ddts: '', // --订单台数
          sjscjts: '', // --实际生产台数
          sjscrs: '', // --实际生产人数
          sjsbgs: '', //  --实际上班工时
          isxp: '',
          zhdj: '',
          jjzgz: '',
          xcpsx: '',
          zgz: '',
          jszgz: '',
          zrscdw: '',
          zrr_empid: '', // --责任人工号id
          zrr_empname: "",
          remark: '', // --未完成原因
          bzyhs: '',
          ddljwcs: ''
        }
        this.showEditSC = true
      } else
      {
        // 去人员明细 
        this.editType = 'add'
        this.ryForm = {
          mautoid: null,
          empid: '', // --总计划单号
          empcode: '',
          empname: '',
          sbgs: '', // --上班工时
          qjgs: '', // --请假工时
          sjsbgs: '', // --实际上班工时
          dtllgz: '', // --当天理论工资
          dtllsx: '', // --当天理论时薪
          isjd: false,  // --是否借调
          remark: '' // --未完成原因 
        }
        this.showEditRY = true
      }
    },
    // 子表修改
    goToEdit () {
      const checkedData = this.auditTableData.filter(it => { return it.checked })
      if (checkedData.length != 1)
      {
        Toast.fail('请选择1条记录！');
        return
      }
      if (this.activeSon == '1')
      {
        // 去生产明细
        this.editType = 'edit'
        this.scForm = { ...this.scForm, ...checkedData[0] }
        this.showEditSC = true
      } else
      {
        // 去人员明细 
        this.editType = 'edit'
        this.ryForm = { ...this.ryForm, ...checkedData[0] }
        this.showEditRY = true
      }
    },
    // 子表删除
    goToDelete () {
      const checkedData = this.auditTableData.filter(it => { return it.checked })
      if (checkedData.length == 0)
      {
        Toast.fail('请选择记录！');
        return
      }
      const ids = checkedData.map(it => { return it.autoid }) || []
      let params = {
        autoidlist: ids.join(','),
        username: this.userInfo.username
      }
      if (this.activeSon == '1')
      {
        // 生产明细删除 
        Dialog.confirm({
          // title: '标题',
          message: this.$t('module.sureToDelete')
        }).then(() => {
          deleteZzScmxRecord(params).then(res => {
            if (res.data[0].info)
            {
              Toast.fail(res.data[0].info)
            } else
            {
              Toast.success(this.$t('module.DelSuc'))
              this.getZzInputDataInfo()
            }
          })
        }).catch(() => {
          // on cancel
        });
      } else
      {
        // 人员明细删除 
        Dialog.confirm({
          // title: '标题',
          message: this.$t('module.sureToDelete')
        }).then(() => {
          deleteZzEmpRecord(params).then(res => {
            if (res.data[0].info)
            {
              Toast.fail(res.data[0].info)
            } else
            {
              Toast.success(this.$t('module.DelSuc'))
              this.getZzInputDataInfo()
            }
          })
        }).catch(() => {
          // on cancel
        });
      }
    },
    //  页签切换事件
    changTab (name, title) {
      if (name == '1')
      {
        // 生产明细
        this.showSonBtn = true
        this.auditThList = [
          {
            fieldname: "checked",
            chnname: "",
            gridwith: 10,
            datatype: 'checkbox'
          },
          {
            fieldname: "orderid",
            chnname: "计划单号",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "cpxh",
            chnname: "产品型号",
            gridwith: 40,
            datatype: 'str'
          },
          {
            fieldname: "zhmc",
            chnname: "组合名称",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "xqfl",
            chnname: "需求分类",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "ddts",
            chnname: "订单总数/台",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "pcsl",
            chnname: "批次数量/台",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "drjhsl",
            chnname: "当日计划数量/台",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "sjscrs",
            chnname: "实际生产人数/人",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "sjsbgs",
            chnname: "实际上班工时/小时",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "isxp",
            chnname: "是否为新产品",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "zhdj",
            chnname: "组合单价/（元/台）",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "jjzgz",
            chnname: "计件总工资/元",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "xcpsx",
            chnname: "新产品时薪/（元/小时）",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "jszgz",
            chnname: "计时总工资/元",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "zgz",
            chnname: "总工资/元",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "remark",
            chnname: "未完成原因",
            gridwith: 100,
            datatype: 'str'
          },
          {
            fieldname: "zrscdw",
            chnname: "责任生产单位",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "zrr_empname",
            chnname: "责任人",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "bzyhs",
            chnname: "包装验货数",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "ddljwcs",
            chnname: "订单累计完成数/台",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "ddwwcs",
            chnname: "订单未完成数/台",
            gridwith: 60,
            datatype: 'str'
          }
        ]
        this.auditTableData = this.scmx_data
      } else if (name == '2')
      {
        // 工序明细
        this.showSonBtn = false
        this.auditThList = [
          {
            fieldname: "checked",
            chnname: "",
            gridwith: 10,
            datatype: 'checkbox'
          },
          {
            fieldname: "cpxh",
            chnname: "产品型号",
            gridwith: 40,
            datatype: 'str'
          },
          {
            fieldname: "xhbm",
            chnname: "型号编码",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "zhmc",
            chnname: "组合名称",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "xqfl",
            chnname: "需求分类",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "gzlb",
            chnname: "工资类别",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "gxmc",
            chnname: "工序名称",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "gws",
            chnname: "岗位数/个",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "gwsczdj",
            chnname: "岗位生产总单价/元",
            gridwith: 60,
            datatype: 'str'
          }
        ]
        this.auditTableData = this.gxmx_data
      } else
      {
        // 人员明细
        this.showSonBtn = true
        this.auditThList = [
          {
            fieldname: "checked",
            chnname: "",
            gridwith: 10,
            datatype: 'checkbox'
          },
          {
            fieldname: "empcode",
            chnname: "工号",
            gridwith: 50,
            datatype: 'str'
          },
          {
            fieldname: "empname",
            chnname: "姓名",
            gridwith: 50,
            datatype: 'str'
          },
          {
            fieldname: "sbgs",
            chnname: "上班工时/小时",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "qjgs",
            chnname: "请假工时/小时",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "sjsbgs",
            chnname: "实际上班工时/小时",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "dtllgz",
            chnname: "当天理论工资/元",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "dtllsx",
            chnname: "当天理论时薪/（元/小时）",
            gridwith: 80,
            datatype: 'str'
          },
          {
            fieldname: "isjd",
            chnname: "是否借调",
            gridwith: 60,
            datatype: 'checkbox_disable'
          },
          {
            fieldname: "remark",
            chnname: "备注",
            gridwith: 100,
            datatype: 'str'
          },
          {
            fieldname: "sscb",
            chnname: "所属厂别",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "ssbz",
            chnname: "所属班/组/线",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "sszxbm",
            chnname: "所属最小部门",
            gridwith: 60,
            datatype: 'str'
          }
        ]
        this.auditTableData = this.rymx_data
      }
    },
    // 点击表格行
    changeAuditDataByTd (val) {
      this.auditTableData.forEach(ele => {
        if (val.autoid == ele.autoid)
        {
          ele.checked = !ele.checked
        }
      });
      this.$forceUpdate()
    },
    // 暂存
    staging () {
      if (!this.form.min_deptname)
      {
        Toast.fail('最小部门不能为空！')
        return
      }
      if (!this.form.yymmdd)
      {
        Toast.fail('生产日期不能为空！')
        return
      }
      return new Promise((resolve, reject) => {
        this.form.empid = this.userInfo.empid
        this.form.username = this.userInfo.username
        saveZzInputData(this.form).then(res => {
          if (res.data[0].info)
          {
            Toast.fail(res.data[0].info)
          } else
          {
            this.form.autoid = res.data[0].autoid
            this.autoid = res.data[0].autoid
            Toast.success('暂存成功！')
            this.getZzInputDataInfo()
            resolve(this.autoid)
          }
        })
      })
    },
    // 总装主表及3个子表数据
    getZzInputDataInfo () {
      getZzInputData({
        autoid: this.autoid,
        username: this.userInfo.username
      }).then(res => {
        const info = res.baseinfo[0]
        if (info)
        {
          this.form = { ...this.form, ...info }
          this.form.createdPeo = info.empname
        }
        this.scmx_data = res.scmx_data
        this.gxmx_data = res.gxmx_data
        this.rymx_data = res.rymx_data
        if (this.activeSon == '1')
        {
          this.scmx_data.forEach(ele => {
            ele.checked = false
          });
          this.auditTableData = this.scmx_data
        } else if (this.activeSon == '2')
        {
          this.auditTableData = this.gxmx_data
        } else
        {
          this.rymx_data.forEach(ele => {
            ele.checked = false
          });
          this.auditTableData = this.rymx_data
        }
      })
    },
    //  提交
    async submit () {
      if (this.autoid)
      {
        postZzInputData({
          autoid: this.autoid,
          username: this.userInfo.username
        }).then(res => {
          if (res.data[0].info)
          {
            Toast.fail(res.data[0].info)
          } else
          {
            Dialog.alert({
              message: '提交成功！',
            }).then(() => {
              this.onClickLeft()
            });
          }
        })
      } else
      {
        this.staging().then(res => {
          postZzInputData({
            autoid: this.autoid,
            username: this.userInfo.username
          }).then(res => {
            if (res.data[0].info)
            {
              Toast.fail(res.data[0].info)
            } else
            {
              Dialog.alert({
                message: '提交成功！',
              }).then(() => {
                this.onClickLeft()
              });
            }
          })
        })
      }
    },
    // 获取默认值
    getCurUserInfoData () {
      getCurUserInfo({
        moduleno: this.moduleno,
        username: this.userInfo.username
      }).then(res => {
        const result = res.data[0]
        this.form.empcode = result.empcode
        this.form.empname = result.empname
        this.form.deptname1 = result.deptname1
        this.form.deptname2 = result.deptname2
        this.form.listno = result.listno
        this.form.createdPeo = result.empname
      })
    },
    // 回退
    onClickLeft () {
      if (this.$route.query.autoid)
      {
        this.$router.push('/toDoList')
      } else
      {
        this.$router.push('/home')
      }
    },
    // 树选择回调
    treeSelectValEvent (data) {
      if (this.TreeName == 'deptnumtree')
      {
        this.form.min_deptname = data.deptname;
      } else if (this.TreeName == 'employee')
      {
        console.log(data, 'data');
        if (this.whichTree == 'emp')
        {
          this.scForm.zrr_empid = data.empid
          this.scForm.zrr_empname = data.empname
        } else
        {
          this.ryForm.empid = data.empid
          this.ryForm.empcode = data.empcode
          this.ryForm.empname = data.empname
        }
      }
    },
    // 点击日期选择事件
    getDatePicker (val) {
      this.whichTree = val
      if (val == 'dept')
      {
        this.TreeName = 'deptnumtree'
        this.$refs.popTreeNode.module = "817";
        this.$refs.popTreeNode.treename = "deptnumtree";
        this.$refs.popTreeNode.editstate = 2;
        this.$refs.popTreeNode.showEmpTreePop('选择部门');
      } else if (val == 'emp' || val == "ryemp")
      {
        this.TreeName = 'employee'
        this.$refs.popTreeNode.module = "817";
        this.$refs.popTreeNode.treename = "emptree";
        this.$refs.popTreeNode.editstate = 2;
        this.$refs.popTreeNode.showEmpTreePop(val == 'emp' ? '选择责任人' : "选择人员");
      } else
      {
        this.showCalendar = true;
      }
    },
    // 日期选择回调
    onConfirmCal (date) {
      const val = this.formatDate(date, 'yyyy-MM-dd')
      this.form.yymmdd = val
      this.showCalendar = false
    },
    // 清除日期或时间
    clearDate () {
      this.form.yymmdd = ''
    },
    // 不够就补零
    add0 (m) {
      return m < 10 ? '0' + m : m
    },
    // 日期时间转换格式
    formatDate (date, rule) {
      if (date)
      {
        const time = new Date(date)
        const y = time.getFullYear()
        const m = time.getMonth() + 1
        const d = time.getDate()
        const h = time.getHours()
        const mm = time.getMinutes()
        const s = time.getSeconds()
        if (rule == 'yyyy-MM-dd')
        {
          return y + '-' + this.add0(m) + '-' + this.add0(d)
        } else
        {
          return (
            y +
            '-' +
            this.add0(m) +
            '-' +
            this.add0(d) +
            ' ' +
            this.add0(h) +
            ':' +
            this.add0(mm) +
            ':' +
            this.add0(s)
          )
        }
      } else
      {
        return ''
      }
    }
  }

}
</script>

<style scoped lang="less">
.editSC_main_pop {
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    height: calc(100% - 96px);
    margin-top: 96px;
    /deep/.van-form {
      height: calc(100% - 80px);
      overflow-y: auto;
      .van-field {
        .van-field__label {
          width: 8em;
        }
      }
      .not_canedi {
        .van-field__value {
          .van-field__body {
            .van-field__control {
              color: #999999;
            }
          }
        }
      }
    }
    .footerBox {
      height: 80px;
      background: #fff;
      display: flex;
      align-items: center;
      .flexBox {
        width: 100vw;
        flex-wrap: nowrap;
        overflow: hidden;
        height: 80%;
        display: flex;
        justify-content: space-around;
      }
    }
  }
}
.zzCheckIn_main {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    // height: calc(100% - 216px);
    margin-top: 96px;
    overflow-y: auto;
    // display: flex;
    // flex-direction: column;
    .form_and_table {
      // height: calc(100% - 120px);
      // flex: 1;
      // display: flex;
      // flex-direction: column;
      /deep/.van-form {
        margin-bottom: 16px;
        .van-field {
          .van-field__label {
            width: 8em;
          }
        }
        .not_canedi {
          .van-field__value {
            .van-field__body {
              .van-field__control {
                color: #999999;
              }
            }
          }
        }
      }
      .isSon_body {
        // flex: 1;
        // display: flex;
        // flex-direction: column;
        .sonTable_btn {
          display: flex;
          align-items: center;
          background: #ffffff;
          padding: 16px;
          .van-button {
            width: 120px;
            height: 60px;
            margin-right: 16px;
            font-size: 26px;
          }
        }
        .sontable_box {
          overflow-x: auto;
          background: #ffffff;
          // flex: 1;
          .table {
            width: 100%;
            border-spacing: 0px;
            tr {
              td:nth-child(1) {
                border-left: 2px solid #dcdfe6;
              }
              th,
              td {
                padding: 6px 10px;
                text-align: center;
                border-bottom: 2px solid #dcdfe6;
                border-right: 2px solid #dcdfe6;
                .comm_box {
                  display: flex;
                  justify-content: center;
                  .boolean_box {
                    .van-checkbox {
                      justify-content: center;
                      /deep/.van-checkbox__icon--checked {
                        .van-icon {
                          color: #fff !important;
                          background-color: #1989fa !important;
                          border-color: #1989fa !important;
                        }
                      }
                      /deep/.van-checkbox__icon--disabled .van-icon {
                        background-color: #fff;
                      }
                    }
                  }
                  .input_box {
                    width: 150px;
                    .van-cell {
                      font-size: 24px;
                      border: 2px solid #d2d2d2;
                      border-radius: 10px;
                      padding: 8px 16px;
                    }
                  }
                }
              }
            }
            .top {
              position: sticky;
              top: 0;
              font-size: 28px;
              background: #e9f3fd;
              height: 64px;
              th:nth-child(1) {
                border-left: 2px solid #dcdfe6;
              }
              th {
                border-top: 2px solid #dcdfe6;
              }
            }
            .cont {
              height: 64px;
              font-size: 24px;
            }
            .cont:nth-child(2n-1) {
              background: #f5f5f5;
            }
            .nodata {
              td {
                font-size: 28px;
              }
            }
          }
        }
      }
    }
  }
  .footerBox {
    height: 80px;
    background: #fff;
    display: flex;
    align-items: center;
    .flexBox {
      width: 100vw;
      flex-wrap: nowrap;
      overflow: hidden;
      height: 80%;
      display: flex;
      justify-content: space-around;
    }
  }
}
</style>